import React from 'react'
import BasicLayout from 'src/components/BasicLayout'


function CalendarPage() {
  return (
    <BasicLayout>
      <iframe src="https://calendar.google.com/calendar/appointments/AcZssZ0VZwQadYoVsp4loaFmJXGlSCW5pqLDEBjn_rw=?gv=true" style={{
              border: "0",
            }} width="100%" height="600" frameborder="0"></iframe>
    </BasicLayout>
  )
}

export default CalendarPage
